import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';

const SuccessPage = ({ location }) => (
  <Layout bgOpacity={0.8} location={location}>
    <SEO title="Success" />

    <div className="flex flex-col justify-center w-full w-full md:max-w-md text-grey-600">
      <h1 className="text-grey-600 text-center">Message Sent</h1>
      <h2 className="text-grey-600 text-center">
        Thanks for contacting me. I will be in touch shortly.
      </h2>
    </div>
  </Layout>
);

export default SuccessPage;
